/* FallingTextFeature.css */
.falling-text-feature {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, rgb(65, 92, 245), rgb(23, 238, 184));
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  height: 800px; /* 或者根据需要调整高度 */
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
}

.top-left-text {
  position: absolute;
  padding-top: 20px;

  font-size: 1.5em;
  color: #fff;
  font-weight: bold; /* 添加粗体样式 */
}

.falling-text-content {
  position: relative;
  width: 100%;
  height: auto;
}

.falling-text-row {
  justify-content: center; /* 确保字符在容器中水平居中 */
  align-items: center; /* 确保字符在容器中垂直居中 */
  margin-bottom: 10px; /* 行间距 */
}

.falling-text-item {
  opacity: 0;
  display: inline-block;
  transform: translateY(-50px);
  font-size: 3em;
  color: #fff;
  animation: fall 1s forwards;
  margin: 0 5px; /* 添加左右间距以防止重叠 */
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Feature.css */
.feature {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-align: center;
  height: 700px;
  justify-content: flex-start;
  padding: 20px;
  padding-bottom: 0px;
}

.feature-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.feature-title {
  font-size: 1.5em;
  margin: 20px 0 10px;
  font-weight: bold;
}

.feature-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
  font-weight: bold;
}

.feature-description1 {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
  font-weight: bold;
}

.feature-image-container {
  overflow: hidden;
  align-self: center;
  height: 80%;
  perspective: 1000px; /* 添加透视效果 */
}

.feature-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s, box-shadow 0.3s;

}

.feature-image:hover {
  transform: scale(1.01) rotateY(5deg); /* 增加3D旋转效果 */
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1); /* 增加悬停时的阴影效果 */
}

.feature-video-container {
  overflow: hidden;
  align-self: center;
  height: 100%;
}

.feature-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* src/components/Contact.css */
.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .contact-container p {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #555;
  }
  
  .contact-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-container a:hover {
    text-decoration: underline;
  }
  
  .wechat-section {
    display: flex;
    align-items: center;
  }
  
  .qr-code {
    width: 100px;
    height: 100px;
    margin-left: 10px;
  }
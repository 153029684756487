body {
  font-family: 'Roboto', sans-serif;
  padding-top: 60px; /* 根据导航条的高度调整这个值 */
  background-color: rgba(203, 201, 201, 0.124);
}

.navbar-brand {
  font-weight: bold;
  color: #007bff;
}

.nav-link {
  color: #6c757d !important;
  margin-right: 20px;
}

.btn-primary {
  background-color: #000;
  border-color: #000;
}

.btn-primary:hover {
  background-color: #333;
  border-color: #333;
}

.container {
  padding: 20px;
}

.hero-section {
  text-align: center;
  padding: 50px 0;
}

.feature-section, .features-list {
  margin: 20px 0;
}

.feature-item {
  display: flex;
  margin-bottom: 20px;
}

.feature-icon img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.feature-text h2, .feature-text h3 {
  color: #007bff;
}

.feature-text p {
  margin-top: 5px;
}


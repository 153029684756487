.hero-section {
  height: 660px;
  padding: 20px;
  box-sizing: border-box; /* 确保填充包含在高度计算内 */
  margin-top: 20px;
}

.hero-text h1 {
  font-family: 'Arial', sans-serif; /* 字体 */
  color: black; /* 颜色 */
  font-size: 2.5em; /* 字体大小 */
  margin-bottom: 30px; /* 底部间距 */
}

.hero-text p {
  font-family: 'Arial', sans-serif; /* 字体 */
  color: black; /* 颜色 */
  font-size: 1.2em; /* 字体大小 */
  margin-bottom: 30px; /* 底部间距 */
  text-align: center; /* 文本居中对齐 */
}

.btn-container {
  margin-top: 50px; /* 确保按钮和文本之间有间距 */
  margin-bottom: 20px;
}

.btn-primary {
  display: inline-block;
  padding: 0; /* 移除默认的内边距 */
  border: none; /* 移除默认的边框 */
  background: none; /* 移除默认的背景 */
}

.button-icon {
  width: 100%; /* 确保按钮图标自适应 */
  height: auto; /* 确保按钮图标自适应 */
}

.btn-container.small .button-icon {
  width: 100px;
}

.btn-container.medium .button-icon {
  width: 150px;
}

.btn-container.large .button-icon {
  width: 200px;
}

.hero-image {
  object-fit: contain; /* 保持宽高比并调整图片以适应容器 */
}
/* src/components/Privacy.css */
.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-container h1,
  .privacy-container h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .privacy-container p {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #555;
  }
/* Navbar.css */
.navbar-brand {
  font-weight: bold;
  color: #007bff;
}

.navbar-logo {
  height: 35px; /* 你可以根据需要调整这个大小 */
}

.nav-link {
  color: #6c757d !important;
  margin-right: 20px;
}

.btn-primary {
  background-color: #000;
  border-color: #000;
}

.btn-primary:hover {
  background-color: #333;
  border-color: #333;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* 确保导航条在其他内容上方 */
}

@media (max-width: 991.98px) {
  .navbar-nav {
    text-align: center;
  }
  .nav-link {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
/* FeatureList.css */
.feature-list-container {
  margin-bottom: 40px; /* 确保标题和FeatureList之间有间距 */
}

.feature-list-title {
  text-align: left;
  font-size: 2.8em;
  color: #333;
  margin-bottom: 20px;
  padding-left: 15px; /* 确保与col的边距对齐 */
  font-weight: bold; /* 添加粗体样式 */
}

.custom-title-class1 {
  color: orange; /* 自定义样式1 */
  font-weight: bold; /* 添加粗体样式 */
}

.custom-title-class2 {
  color: green; /* 自定义样式2 */
  font-weight: bold; /* 添加粗体样式 */
}

.custom-title-class3 {
  color: blue; /* 自定义样式3 */
}

.custom-title-class4 {
  color: purple; /* 自定义样式4 */
}

.custom-title-class5 {
  color: orange; /* 自定义样式5 */
}

.feature-list {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f5f5f5;
}

.feature-item {
  margin: 10px;
  display: flex;
  flex-direction: column; /* 确保每个Feature项内部为垂直布局 */
}
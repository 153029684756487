/* FullImageFeature.css */
.full-image-feature {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  height: 700px; /* 或者根据需要调整高度 */
  justify-content: flex-start;
  padding: 20px;
  padding-bottom: 0px;
  background-size: cover;
  background-position: center;
}

.text-container {
  background-color: rgba(255, 255, 255, 0.8); /* 半透明背景，以确保文字可读 */
  padding: 20px;
  border-radius: 10px;
  margin: 20px; /* 与边界的间距 */
  align-self: flex-start; /* 垂直方向上对齐文字容器 */
}

.feature-title {
  font-size: 1.5em;
  margin: 20px 0 10px;
  line-height: 1.6; /* 设置行间距 */
}

.feature-description {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.3; /* 设置行间距 */
}
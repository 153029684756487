/* src/components/MealPlanPage/MealPlanPage.css */
.meal-plan-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .meal-plan-title {
    text-align: center;
    color: #34495e;
    font-size: 2em;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
  }
  
  .loading, .error {
    text-align: center;
    padding: 50px;
    font-size: 1.2em;
    color: #666;
  }
  
  .feature-section {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 6px;
    margin: 20px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .day-section {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    margin: 20px 0;
    overflow: hidden;
    background: white;
  }
  
  .day-header {
    background: #4a90e2;
    color: white;
    padding: 15px 20px;
    margin: 0;
  }
  
  .meal-section {
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .meal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .meal-time {
    color: #666;
    font-size: 0.9em;
  }
  
  .dish-list {
    list-style: none;
    padding-left: 0;
  }
  
  .dish-list li {
    padding: 8px 0;
    border-bottom: 1px dashed #eee;
  }
  
  .dish-card {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 20px;
    margin: 15px 0;
    background: white;
  }
  
  .tags {
    margin: 10px 0;
  }
  
  .tag {
    display: inline-block;
    background: #e9ecef;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 2px;
    font-size: 0.9em;
    color: #495057;
  }
  
  .ingredients-list {
    list-style: none;
    padding-left: 0;
  }
  
  .ingredients-list li {
    padding: 4px 0;
  }
  
  .steps-list {
    padding-left: 20px;
  }
  
  .steps-list li {
    margin-bottom: 10px;
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    .meal-plan-container {
      padding: 10px;
    }
  
    .meal-plan-title {
      font-size: 1.5em;
    }
  
    .meal-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .meal-time {
      margin-top: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .meal-plan-title {
      font-size: 1.2em;
    }
  
    .dish-card {
      padding: 15px;
    }
  }
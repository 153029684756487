/* src/components/ImageButtonFeature.css */
.image-button-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .image-button-feature:hover {
    transform: scale(1.05);
  }
  
  .feature-description {
    font-size: 1em;
    color: #000;
    margin-bottom: 20px;
    line-height: 1.6;
    font-weight: bold;
  }
  
  .button-image {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .button-image:hover {
    transform: scale(1.1);
  }
/* HoverFeature.css */
.hover-feature {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    height: 700px;
    justify-content: flex-start;
    padding: 20px;
    padding-bottom: 0px;
  }
  
  .hover-feature-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .hover-feature-title {
    font-size: 1.5em;
    margin: 20px 0 10px;
  }
  
  .hover-feature-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .hover-feature-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px; /* 添加3D透视 */
  }
  
  .hover-feature-image,
  .hover-feature-hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden; /* 隐藏翻转后的背面 */
    transition: transform 0.6s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .front {
    transform: rotateY(0deg); /* 初始状态 */
  }
  
  .back {
    transform: rotateY(180deg); /* 翻转后的状态 */
  }
  
  .flip .front {
    transform: rotateY(180deg); /* 翻转 */
  }
  
  .flip .back {
    transform: rotateY(0deg); /* 翻转 */
  }
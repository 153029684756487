/* GradientTextFeature.css */
.gradient-text-feature {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, rgb(192, 28, 246), rgb(235, 57, 244));
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    height: 800px; /* 或者根据需要调整高度 */
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
  }
  
  .top-left-text {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.5em;
    color: #fff;
  }
  
  .center-text {
    width: 80%;
    height: auto;
    font-size: 2em;
    color: #fff;
    line-height: 2;
  }
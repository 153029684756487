/* src/components/ArticlePage.css */
.article-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .article-title {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .article-content {
    font-size: 1em;
    line-height: 1.6;
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    .article-title {
      font-size: 1.5em;
    }
  
    .article-content {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .article-title {
      font-size: 1.2em;
    }
  
    .article-content {
      font-size: 0.8em;
    }
  }